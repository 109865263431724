import { Database } from '~/types/supabase'
import { createClient } from '@supabase/supabase-js'
import { setAccount } from '~/services/account/account'
import * as Sentry from "@sentry/browser"

const supabase = createClient<Database>(import.meta.env.VITE_SUPABASE_URL, import.meta.env.VITE_SUPABASE_API_KEY)

supabase.auth.onAuthStateChange((event, session) => {
  if (event === 'SIGNED_IN' && session){
    setAccount('id', session.user.id)
    Sentry.setUser({
      id: session.user.id
    })
  }
})

const getUserToken = async () => {
  const token = ((await supabase.auth.getSession()).data.session?.access_token)
  if (!token) {
    window.location.href = '/account/login'
    // throw new Error('invalid_token')
  }
  return token
}

export {
  supabase,
  getUserToken
}