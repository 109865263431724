import * as Sentry from "@sentry/browser"
import { PostgrestError } from '@supabase/supabase-js'
import { APIErrorParams } from '~/types/api/errors'

const reportError = (e: Error | PostgrestError) => {
  Sentry.captureException(e)
  console.log(e)
}

const createAPIError = ({ error, code, message }: APIErrorParams) => {
  if(error){
    Sentry.captureException(error)
    console.log(error)
  }else{
    Sentry.captureException({ code, message })
    console.log({ code, message })
  }

  return new Response(JSON.stringify({
    error: {
      code,
      message
    }
  }))
}

export {
  reportError,
  createAPIError
}