import { createStore } from 'solid-js/store'
import { Account } from '~/types/account/account'

const defaultState: Account = {
  id: null,
  subscription: null
}

const [account, setAccount] = createStore<Account>(defaultState)

export {
  account,
  setAccount
}